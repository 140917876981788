import { LoginFlow } from "@ory/client";
import axios from "axios";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useOry } from "~/hooks/use-ory";
import { analytics, ButtonClickEvent, ScreenShownSocialEvent } from "~/libs/analytics";
import { handleFlowError } from "~/libs/ory";
import { Box, Button, Heading, Icon, Text } from "~/ui/components";

import { FormReturn } from "./form";

interface Props {
  flow: LoginFlow;
  form: FormReturn;
  loginProviders: string[];
}

const SelectProvider = ({ flow, form, loginProviders }: Props) => {
  const { t } = useTranslation();
  const ory = useOry();
  const router = useRouter();

  const onSocialLogin = async (provider: string) => {
    const csrfToken = form.getValues("csrf_token");

    if (!flow || !csrfToken) return;

    try {
      analytics.track(
        new ButtonClickEvent({
          button: `continue with ${provider}`,
          screen: "login: social",
        }),
      );

      await ory.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: {
          csrf_token: csrfToken,
          method: "oidc",
          provider,
          upstream_parameters: {
            ...(provider === "apple" && { prompt: "login" }),
            ...(provider === "google" && { prompt: "login" }),
            ...(provider === "facebook" && { auth_type: "reauthenticate" }),
          },
        },
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      await handleFlowError(router, "login")(error);
    }
  };

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownSocialEvent({
        provider: String(loginProviders[0]),
      }),
    );
  }, [loginProviders]);

  useEffect(() => screenShown(), [screenShown]);

  return (
    <Box space="large">
      <Box space="small">
        <Heading element="h3">{t("login.socialAccount.heading")}</Heading>

        <Text>
          {t("login.socialAccount.introductory[0]")}
          &nbsp;
          {loginProviders[0]}
          &nbsp;
          {t("login.socialAccount.introductory[1]")}
        </Text>
      </Box>

      <Box space="small" className="social-login__wrapper">
        {loginProviders.includes("apple") && (
          <Button
            variant="light"
            name="provider"
            value="apple"
            startIcon={<Icon icon="apple" />}
            onClick={() => onSocialLogin("apple")}>
            {t("login.continueWithApple")}
          </Button>
        )}

        {loginProviders.includes("facebook") && (
          <Button
            variant="light"
            name="provider"
            value="facebook"
            startIcon={<Icon icon="facebook" />}
            onClick={() => onSocialLogin("facebook")}>
            {t("login.continueWithFacebook")}
          </Button>
        )}

        {loginProviders.includes("google") && (
          <Button
            variant="light"
            name="provider"
            value="google"
            startIcon={<Icon icon="google" />}
            onClick={() => onSocialLogin("google")}>
            {t("login.continueWithGoogle")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export { SelectProvider };
