/* eslint-disable react-hooks/exhaustive-deps */
import { VerificationFlow } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import { analytics, ButtonClickEvent, ScreenShownEvent, trackFlow } from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import {
  handleFlowError,
  setFormValues,
  setUriFlow,
  setFormErrors,
} from "~/libs/ory";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
} from "~/ui/components";

import { FlowMessage } from "./components/flow-message";

type FormData = {
  csrf_token: string;
  email: string;
  code: string;
  method: string;
};

const Verification = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const ory = useOry();
  const form = useForm<FormData>();
  const [verificationInProgress, setVerificationInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = form;

  const [flow, setFLow] = useState<VerificationFlow>();

  const { flow: flowId, return_to: returnTo } = router.query;

  const onSubmit = async (data: FormData) => {
    if (!flow) return;

    await setUriFlow(router, flow.id);

    const body = {
      csrf_token: data.csrf_token,
      flow: flow.id,
      email: data.email,
      code: data.code,
      method: data.method,
    };

    try {
      setVerificationInProgress(true);

      analytics.track(
        new ButtonClickEvent({
          button: "verify",
          screen: "verification",
        }),
      );

      const { data } = await ory.updateVerificationFlow({
        flow: flow.id,
        // @ts-ignore
        // ory/client library does not ship with correct type
        updateVerificationFlowBody: body,
      });

      setFLow(data);
      trackFlow({ flow, screen: "verification" });

      if (data.state === "passed_challenge") {
        router.push("/");
      }
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "verification" });

      await handleFlowError(router, "verification")(error);

      // form validation error
      if (error.response?.status === 400) {
        setFLow(error.response.data);
        setFormErrors(form, error.response.data);
      }
    } finally {
      setVerificationInProgress(false);
    }
  };

  useEffect(() => {
    const verificationFlow = async () => {
      if (!router.isReady || flow) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getVerificationFlow({
            id: String(flowId),
          });

          setFLow(data);
          setFormValues(form, data);
          trackFlow({ flow: data, screen: "verification" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;
          trackFlow({ flow: error.response?.data, screen: "verification" });
          await handleFlowError(router, "verification")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserVerificationFlow({
          returnTo: returnTo ? urlForPath(String(returnTo)) : undefined,
        });

        setFLow(data);
        trackFlow({ flow: data, screen: "verification" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        trackFlow({ flow: error.response?.data, screen: "verification" });
        await handleFlowError(router, "verification")(error);
      }
    };

    verificationFlow();
  }, [router, router.isReady, flow, flowId, returnTo]);

  useEffect(() => {
    if (!router.isReady) return;

    const { identifier } = router.query;

    if (!identifier || typeof identifier !== "string") {
      router.push("/registration");
      return;
    }

    setValue("email", identifier);
  }, [router.isReady]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "verification",
      }),
    );
  }, []);

  useEffect(() => screenShown, [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("verification.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Heading element="h3">{t("verification.heading")}</Heading>
            <FlowMessage flow={flow}></FlowMessage>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register("csrf_token")} />
              <input type="hidden" {...register("method")} />

              <Box space="small">
                <FormField>
                  <InputField
                    autoComplete="username"
                    placeholder={t<string>("common.email")}
                    readOnly
                    {...register("email")}
                  />
                  {errors.email && <Error>{errors.email.message}</Error>}
                </FormField>
                <FormField>
                  <InputField
                    autoComplete="one-time-code"
                    placeholder={t<string>("common.code")}
                    {...register("code")}
                  />
                  {errors.code && <Error>{errors.code.message}</Error>}
                </FormField>
                <Button disabled={verificationInProgress}>
                  {t("common.verify")}
                </Button>
              </Box>
            </form>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { Verification };
