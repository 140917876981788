import Link from "next/link";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  analytics,
  ScreenShownEvent,
  ButtonClickEvent,
} from "~/libs/analytics";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
  Text,
} from "~/ui/components";

import { FormReturn } from "./form";

interface Props {
  form: FormReturn;
  onSubmit: any; // TODO:
}

const trackForgotPassword = () => {
  analytics.track(
    new ButtonClickEvent({
      button: "forgot password",
      screen: "login: password input",
    }),
  );
};

const InputPassword = ({ form, onSubmit }: Props) => {
  const { t } = useTranslation();

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "login: password input",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <input type="hidden" {...form.register("csrf_token")} />
      <input type="hidden" {...form.register("method")} />
      <input type="hidden" {...form.register("identifier")} />

      <Box space="large">
        <Box space="small">
          <Heading element="h3">{t("login.password.heading")}</Heading>
          <Text>{t("login.password.introductory")}</Text>
        </Box>
        <Box space="small">
          <FormField>
            <InputField
              type="password"
              autoComplete="current-password"
              placeholder={t<string>("common.password")}
              {...form.register("password")}
            />
            {form.formState.errors.password && (
              <Error>{form.formState.errors.password.message}</Error>
            )}
          </FormField>
          <Button>{t("common.continue")}</Button>
          <Link
            onClick={trackForgotPassword}
            href={`/forgot-password?identifier=${form.getValues().identifier}`}>
            <Text element="span" variant="primary">
              {t("common.forgotPassword")}?
            </Text>
          </Link>
        </Box>
      </Box>
    </form>
  );
};

export { InputPassword };
