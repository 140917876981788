/* eslint-disable react-hooks/exhaustive-deps */
import { SettingsFlow } from "@ory/client";
import Head from "next/head";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AuthLayout } from "~/features/auth";
import { analytics, ButtonClickEvent, trackFlow } from "~/libs/analytics";
import { setFormErrors, setFormValues } from "~/libs/ory";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
} from "~/ui/components";

import { FlowMessage } from "./flow-message";

type FormData = {
  csrf_token: string;
  password: string;
};

type UpdatePasswordProps = {
  flow?: SettingsFlow;
  updateInProgress: boolean;
  source?: string;
  onSubmit: ({
    csrfToken,
    password,
  }: {
    csrfToken: string;
    password: string;
  }) => Promise<void>;
};

const UpdatePassword = (props: UpdatePasswordProps) => {
  const { t } = useTranslation();
  const form = useForm<FormData>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const trackUpdatePasswordFlow = (props: UpdatePasswordProps) => {
    switch (props.source) {
      case "forgot-password":
        trackFlow({ flow: props.flow, screen: "pw-reset: set new password" });
        break;

      case "recovery":
        trackFlow({
          flow: props.flow,
          screen: "user-migration: set new password",
        });
        break;

      default:
        trackFlow({ flow: props.flow, screen: "settings" });
    }
  };

  const trackUpdatePassword = (source?: string) => {
    switch (source) {
      case "forgot-password":
        analytics.track(
          new ButtonClickEvent({
            button: "set new password",
            screen: "pw-reset: set new password",
          }),
        );
        break;

      case "recovery":
        analytics.track(
          new ButtonClickEvent({
            button: "set new password",
            screen: "user-migration: set new password",
          }),
        );
        break;

      default:
        analytics.track(
          new ButtonClickEvent({
            button: "set new password",
            screen: "settings",
          }),
        );
    }
  };

  const onSubmit = async (data: FormData) => {
    trackUpdatePassword(props.source);

    await props.onSubmit({
      csrfToken: data.csrf_token,
      password: data.password,
    });
  };

  useEffect(() => {
    if (!props.flow) return;

    setFormValues(form, props.flow);
    setFormErrors(form, props.flow);
    trackUpdatePasswordFlow(props);
  }, [props.flow]);

  return (
    <>
      <Head>
        <title>{t("updatePassword.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" {...register("csrf_token")} />

            <Box space="large">
              <Heading element="h3">{t("updatePassword.heading")}</Heading>
              <FlowMessage flow={props.flow}></FlowMessage>
              <FormField>
                <InputField
                  autoComplete="new-password"
                  type="password"
                  placeholder={t<string>("common.password")}
                  {...register("password")}
                />
                {errors.password && <Error>{errors.password.message}</Error>}
              </FormField>
              <Button disabled={props.updateInProgress}>
                {t("updatePassword.saveAndContinue")}
              </Button>
            </Box>
          </form>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { UpdatePassword };
