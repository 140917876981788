import { LoginFlow, UpdateLoginFlowBody } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import { analytics, ButtonClickEvent, trackFlow } from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import {
  handleFlowError,
  setFormErrors,
  setFormValues,
  setUriFlow,
} from "~/libs/ory";
import { Button, Box } from "~/ui/components";

import { FormData, Step, StepTransition } from "./form";
import { InputEmail } from "./input-email";
import { InputPassword } from "./input-password";
import { SelectProvider } from "./select-provider";

const LoginWithEmail = () => {
  const { t } = useTranslation();
  const ory = useOry();
  const router = useRouter();
  const form = useForm<FormData>();

  const [step, setStep] = useState<Step>(Step.InputEmail);
  const [flow, setFlow] = useState<LoginFlow>();
  const [loginProviders, setLoginProviders] = useState<string[]>([]);

  const nextStep = useCallback((transition: StepTransition) => {
    setStep(transition.to);
    if (
      transition.from === Step.InputEmail &&
      transition.to === Step.SelectProvider
    ) {
      setLoginProviders(transition.payload.loginProviders);
    }
  }, []);

  const {
    flow: flowId,
    refresh, // if set we want to refresh the session
    aal, // implies you want to perform two-factor authentication/verification.
  } = router.query;

  const loginWithPassword = async (data: FormData) => {
    if (!flow) return;

    await setUriFlow(router, flow.id);

    analytics.track(
      new ButtonClickEvent({
        button: "continue",
        screen: "login: password input",
      }),
    );

    const body = {
      csrf_token: data.csrf_token,
      identifier: data.identifier,
      password: data.password,
      method: data.method,
    } as UpdateLoginFlowBody;

    try {
      await ory.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: body,
      });
      router.push(flow.return_to!);
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;

      trackFlow({
        flow: error.response?.data,
        screen: "login: password input",
      });

      await handleFlowError(router, "login")(error);

      if (error.response?.status === 400) {
        setStep(Step.InputEmail);
        setFlow(error.response.data);
        setFormErrors(form, error.response.data);
      }
    }
  };

  useEffect(() => {
    const loginFlow = async () => {
      if (!router.isReady || flow) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getLoginFlow({ id: String(flowId) });
          setFlow(data);
          setFormValues(form, data);
          trackFlow({ flow: data, screen: "login: email input" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;

          trackFlow({
            flow: error.response?.data,
            screen: "login: email input",
          });

          await handleFlowError(router, "login")(error);

          if (error.response?.status === 400) {
            setStep(Step.InputEmail);
            setFlow(error.response.data);
            setFormErrors(form, error.response.data);
          }
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserLoginFlow({
          refresh: Boolean(refresh),
          aal: aal ? String(aal) : undefined,
          returnTo: urlForPath(`api/redirect`),
        });
        setFlow(data);
        setFormValues(form, data);
        trackFlow({ flow: data, screen: "login: email input" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        trackFlow({ flow: error.response?.data, screen: "login: email input" });
        await handleFlowError(router, "login")(error);
      }
    };

    loginFlow();
  }, [aal, flow, flowId, form, ory, refresh, router, router.isReady]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("login.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            {step === Step.InputEmail && (
              <InputEmail flow={flow} form={form} nextStep={nextStep} />
            )}

            {step === Step.InputPassword && (
              <InputPassword form={form} onSubmit={loginWithPassword} />
            )}

            {step === Step.SelectProvider && (
              <SelectProvider
                flow={flow}
                form={form}
                loginProviders={loginProviders}
              />
            )}
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;

  .social-login__wrapper > ${Button.Root} {
    justify-content: flex-start;
  }
`;

export { LoginWithEmail };
