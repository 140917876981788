import {
  GenericError,
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  VerificationFlow,
} from "@ory/client";

import { analytics } from "./client";
import { FlowEvent, FlowRedirectEvent } from "./events";
import { Screen } from "./types";

type Flow =
  | LoginFlow
  | RecoveryFlow
  | RegistrationFlow
  | SettingsFlow
  | VerificationFlow;

type TrackFlowProps = {
  flow?: Record<string, any>;
  screen: Screen;
};

export const trackFlow = (props: TrackFlowProps) => {
  const { flow: data, screen } = props;

  if (!data) return;

  if (isFlowError(data)) {
    analytics.track(
      new FlowRedirectEvent({
        code: data.error.code || 0,
        id: data.error.id || "not-set",
        message: data.error.message || "not-set",
        reason: data.error.reason || "not-set",
        redirect_browser_to: data.redirect_browser_to,
        screen,
      }),
    );
  }

  if (isFlow(data)) {
    data.ui.messages?.map((message) => {
      analytics.track(
        new FlowEvent({
          flowId: data.id || "not-set",
          flowUrl: data.request_url || "not-set",
          messageId: message.id,
          messageText: message.text,
          messageType: message.type,
          screen,
        }),
      );
    });
  }
};

const isFlow = (data: Record<string, any>): data is Flow => {
  return data?.ui?.messages && Array.isArray(data.ui.messages);
};

const isFlowError = (
  data: Record<string, any>,
): data is { error: GenericError; redirect_browser_to?: string } => {
  return data?.error?.id;
};
