import { LoginFlow } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState  } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import { analytics, ScreenShownEvent, trackFlow } from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import { handleFlowError, setFormValues } from "~/libs/ory";
import { Box } from "~/ui/components";

type FormData = {
  csrf_token: string;
};

const LoginWithIdp = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const form = useForm<FormData>();
  const ory = useOry();

  const [flow, setFlow] = useState<LoginFlow>();

  const { flow: flowId, provider } = router.query;

  useEffect(() => {
    const loginFlow = async () => {
      if (!router.isReady) {
        return;
      }

      try {
        const { data } = await ory.createBrowserLoginFlow({
          returnTo: urlForPath(`/api/redirect`),
        });

        setFlow(data);
        setFormValues(form, data);
        trackFlow({ flow: data, screen: "login: social redirect" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;

        trackFlow({
          flow: error.response?.data,
          screen: "login: social redirect",
        });

        await handleFlowError(router, "login")(error);
      }
    };

    loginFlow();
  }, [flowId, form, ory, router, router.isReady]);

  useEffect(() => {
    const loginFlow = async () => {
      const csrfToken = form.getValues("csrf_token");

      if (!flow || !provider || !csrfToken || !router.isReady) {
        return;
      }

      try {
        await ory.updateLoginFlow({
          flow: flow.id,
          updateLoginFlowBody: {
            csrf_token: csrfToken,
            method: "oidc",
            provider: String(provider),
            upstream_parameters: {
              ...(provider === "apple" && { prompt: "login" }),
              ...(provider === "google" && { prompt: "login" }),
              ...(provider === "facebook" && { auth_type: "reauthenticate" }),
            },
          },
        });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;

        trackFlow({
          flow: error.response?.data,
          screen: "login: social redirect",
        });

        await handleFlowError(router, "login")(error);
      }
    };

    loginFlow();
  }, [flow, ory, router, router.isReady, form, provider]);

  useEffect(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "login: social redirect",
      }),
    );
  }, []);

  return (
    <>
      <Head>
        <title>{t("socialLogin.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Box>
              {t("socialLogin.redirectedTo", {provider})}
            </Box>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { LoginWithIdp };
