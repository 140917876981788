import {
  ContinueWithVerificationUi,
  RegistrationFlow,
  UpdateRegistrationFlowBody,
} from "@ory/client";
import { filterNodesByGroups } from "@ory/integrations/ui";
import axios from "axios";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { AuthLayout } from "~/features/auth";
import { OidcCollectMissingData } from "~/features/auth/components";
import { useOry } from "~/hooks/use-ory";
import {
  analytics,
  ButtonClickEvent,
  ScreenShownEvent,
  trackFlow,
} from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import {
  handleFlowError,
  setFormErrors,
  setFormValues,
  setUriFlow,
} from "~/libs/ory";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
  Text,
} from "~/ui/components";

import { FlowMessage } from "./components/flow-message";

type FormData = {
  csrf_token: string;
  method: string;
  traits: {
    name: {
      first: string;
      last: string;
    };
  };
  password: string;
};

const Registration = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const ory = useOry();
  const form = useForm<FormData>();
  const [flow, setFlow] = useState<RegistrationFlow>();
  const [oidcEmailMissing, setOidcEmailMissing] = useState(false);
  const [registrationInProgress, setRegistrationInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const { flow: flowId, identifier } = router.query;

  const trackLogin = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "login",
        screen: "registration: data input",
      }),
    );
  };

  const onSubmit = async (data: FormData) => {
    if (!flow?.id) return;

    await setUriFlow(router, flow.id);

    const body = {
      csrf_token: data.csrf_token,
      traits: {
        name: {
          first: data.traits.name.first,
          last: data.traits.name.last,
        },
        email: identifier,
      },
      password: data.password,
      method: "password",
    } as UpdateRegistrationFlowBody;

    try {
      setRegistrationInProgress(true);

      analytics.track(
        new ButtonClickEvent({
          button: "register",
          screen: "registration: data input",
        }),
      );

      const { data } = await ory.updateRegistrationFlow({
        flow: String(flow.id),
        updateRegistrationFlowBody: body,
      });

      if (data.continue_with) {
        const { email } = data.identity.traits;

        for (const uiFlow of data.continue_with) {
          switch (uiFlow.action) {
            case "show_verification_ui":
              await router.push(
                `/verification?flow=${(uiFlow as ContinueWithVerificationUi).flow.id
                }&identifier=${email}`,
              );
              return;
          }
        }
      }

      await router.push(flow?.return_to || "/");
    } catch (error) {
      if (!axios.isAxiosError(error)) return;

      trackFlow({
        flow: error.response?.data,
        screen: "registration: data input",
      });

      await handleFlowError(router, "registration")(error);

      if (error.response?.status === 400) {
        // form validation error
        setFlow(error.response.data);
        setFormErrors(form, error.response.data);
      }
    } finally {
      setRegistrationInProgress(false);
    }
  };

  useEffect(() => {
    const registrationFlow = async () => {
      if (!router.isReady || flow) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getRegistrationFlow({
            id: String(flowId),
          });

          const groups = filterNodesByGroups({
            nodes: data.ui.nodes,
            groups: "oidc",
            attributes: "email",
          });

          if (groups.length) {
            setOidcEmailMissing(true);
          }

          setFlow(data);
          setFormValues(form, data);
          setFormErrors(form, data);
          trackFlow({ flow: data, screen: "registration: data input" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;

          trackFlow({
            flow: error.response?.data,
            screen: "registration: data input",
          });

          await handleFlowError(router, "registration")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserRegistrationFlow({
          returnTo: urlForPath(`/api/redirect`),
        });

        setFlow(data);
        setFormValues(form, data);
        trackFlow({ flow: data, screen: "registration: data input" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;

        trackFlow({
          flow: error.response?.data,
          screen: "registration: data input",
        });

        await handleFlowError(router, "registration")(error);
      }
    };

    registrationFlow();
  }, [flowId, router, router.isReady, flow, ory, form]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "registration: data input",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  if (oidcEmailMissing && flow) {
    return <OidcCollectMissingData flow={flow} />;
  }

  return (
    <>
      <Head>
        <title>Register</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Heading element="h3">{t("register.heading")}</Heading>
            <FlowMessage flow={flow}></FlowMessage>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="hidden" {...register("csrf_token")} />
              <input type="hidden" {...register("method")} />

              <Box space="small">
                <FormField>
                  <InputField
                    required
                    autoComplete="given-name"
                    placeholder={t<string>("common.firstName")}
                    {...form.register("traits.name.first")}
                  />
                  {errors?.traits?.name?.first && (
                    <Error>{errors?.traits?.name?.first.message}</Error>
                  )}
                </FormField>
                <FormField>
                  <InputField
                    required
                    autoComplete="family-name"
                    placeholder={t<string>("common.lastName")}
                    {...form.register("traits.name.last")}
                  />
                  {errors?.traits?.name?.last && (
                    <Error>{errors?.traits?.name?.last.message}</Error>
                  )}
                </FormField>
                <FormField>
                  <InputField
                    required
                    autoComplete="new-password"
                    type="password"
                    placeholder={t<string>("common.password")}
                    {...register("password")}
                  />
                  {errors.password && <Error>{errors.password.message}</Error>}
                </FormField>
                <Button disabled={registrationInProgress}>
                  {t("common.register")}
                </Button>
              </Box>
            </form>
            <Text>
              {t("register.alreadyClient")}{" "}
              <Text element="span" variant="primary">
                <Link onClick={trackLogin} href="/login">
                  <Text element="span" variant="primary">
                    {t("register.loginHere")}
                  </Text>
                </Link>
              </Text>
            </Text>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { Registration };
