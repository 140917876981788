import Image from "next/image";
import { useRouter } from "next/router";
import styled from "styled-components";

import planturaLogo from "~/assets/plantura-logo-full.svg";
import { Alert, Box, Card } from "~/ui/components";

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  const router = useRouter();

  const { error } = router.query;

  return (
    <Wrapper>
      <Container>
        <Box>
          <Box style={{ margin: "0 auto", padding: "20px" }}>
            <Image src={planturaLogo} alt="Plantura logo" height="46" />
          </Box>
          <Box>
            <Card variant="light" className="auth-content__container">
              <Box space="large">
                {typeof error === "string" && <Alert text={error} />}
                {children}
              </Box>
            </Card>
          </Box>
        </Box>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 480px) {
    height: 100vh;
  }
`;

const Container = styled.div`
  width: 400px;
  margin-bottom: 20px;

  & .auth-content__container {
    padding: 36px;
  }

  @media (max-width: 480px) {
    width: 100%;

    & .auth-content__container {
      border: initial;
      margin: 0 20px 20px 20px;
    }
  }
`;

export { AuthLayout };
