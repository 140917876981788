import { Session } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { FullScreenLoader } from "~/components";
import { AuthLayout } from "~/features/auth";
import { useApi, useToasts } from "~/hooks";
import { useOry } from "~/hooks/use-ory";
import { ApiError } from "~/libs/api";
import { ErrorBody } from "~/libs/types";
import { Box, Button, Heading, Text } from "~/ui/components";

const AccountDelete = () => {
  const { t } = useTranslation();
  const ory = useOry();
  const router = useRouter();
  const { addToast } = useToasts();
  const { inProgress, post } = useApi<{
    data: object;
  }>();

  const [session, setSession] = useState<Session | null>(null);

  const handleDelete = async () => {
    try {
      await post("/api/identity/delete", {});

      addToast({
        title: t("accountDelete.successTitle"),
        description: t("accountDelete.successDescription"),
      });

      router.push("/");
    } catch (err) {
      if (!(err instanceof ApiError)) throw err;
      const error: ErrorBody = err.response.error;

      addToast({
        title: error.title,
        description: error.detail,
      });
    }
  };

  const handleDeleteCancellation = () => {
    router.push("/session");
  };

  useEffect(() => {
    const sessionFlow = async () => {
      try {
        const { data } = await ory.toSession();
        setSession(data);
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        router.push("/");
      }
    };

    sessionFlow();
  }, [ory, router]);

  if (!session) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("accountDelete.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Box space="small">
              <Heading element="h3">{t("accountDelete.heading")}</Heading>
              <Text variant="secondary">{t("accountDelete.introductory")}</Text>
            </Box>
            <Box space="small">
              <Button
                variant="primary"
                onClick={handleDelete}
                disabled={inProgress}>
                {t("common.continue")}
              </Button>
              <Button
                variant="link"
                onClick={handleDeleteCancellation}
                disabled={inProgress}>
                {t("common.cancel")}
              </Button>
            </Box>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div``;

export { AccountDelete };
