import { Screen, Event } from "../types";

export class ButtonClickEvent implements Event {
  constructor(private payload: { button: string; screen: Screen }) {}

  toJson() {
    const button = this.payload.button;
    const name = `clicked ${button.replace(":", " ")} button`;
    return {
      name,
      payload: this.payload,
    };
  }
}
