import { LoginFlow } from "@ory/client";
import { useRouter } from "next/router";
import { FormEvent, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useApi, useToasts } from "~/hooks";
import { analytics, ButtonClickEvent, ScreenShownEvent } from "~/libs/analytics";
import { ApiError } from "~/libs/api";
import { ErrorBody } from "~/libs/types";
import { isValidEmail } from "~/libs/utils";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  Icon,
  IconButton,
  InputField,
  Seperator,
  Text,
} from "~/ui/components";

import { FormReturn, Step, StepTransition } from "./form";
import { FlowMessage } from "../components/flow-message";

interface Props {
  flow: LoginFlow;
  form: FormReturn;
  nextStep(step: StepTransition): void;
}

const SOCIAL_LOGIN_PROVIDERS = ["apple", "google", "facebook"];

const InputEmail = ({ flow, form, nextStep }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { addToast } = useToasts();
  const { inProgress, post } = useApi<{
    data: { providers: string[] };
  }>();

  const {
    formState: { errors },
  } = form;

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      analytics.track(
        new ButtonClickEvent({
          button: "continue",
          screen: "login: email input",
        }),
      );

      const shouldPost = await form.trigger(["identifier"]);

      if (!shouldPost) return;

      try {
        const identifier = form.getValues("identifier");

        const response = await post("/api/login-options", {
          identifier,
        });

        const providers = response?.data?.data.providers || [];

        if (!providers.length) {
          router.push(`/registration?identifier=${identifier}`);
          return;
        }

        if (providers.includes("password")) {
          nextStep({
            from: Step.InputEmail,
            to: Step.InputPassword,
            payload: { email: identifier },
          });
          return;
        }

        if (providers.some((item) => SOCIAL_LOGIN_PROVIDERS.includes(item))) {
          nextStep({
            from: Step.InputEmail,
            to: Step.SelectProvider,
            payload: {
              email: identifier,
              loginProviders: providers,
            },
          });
        }
      } catch (err) {
        if (!(err instanceof ApiError)) throw err;
        const error: ErrorBody = err.response.error;

        addToast({
          title: error.title,
          description: error.detail,
        });
      }
    },
    [addToast, form, nextStep, post, router],
  );

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "login: email input",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  return (
    <Box space="large">
      <form onSubmit={onSubmit} noValidate>
        <Box space="large">
          <Box space="small">
            <Heading element="h3">{t("login.default.heading")}</Heading>
            <Text>{t("login.default.introductory")}</Text>
          </Box>
          <FlowMessage flow={flow}></FlowMessage>
          <Box space="small">
            <FormField>
              <InputField
                type="email"
                autoComplete="username"
                placeholder={t<string>("common.email")}
                {...form.register("identifier", {
                  validate: {
                    matchPattern: (v) =>
                      isValidEmail(v) || t("common.invalidEmail").toString(),
                  },
                })}
              />
              {errors.identifier && <Error>{errors.identifier.message}</Error>}
            </FormField>

            <Button disabled={inProgress}>{t("common.continue")}</Button>
          </Box>
        </Box>
      </form>

      <Seperator>{t("login.default.seperator")}</Seperator>

      <Box>
        <form method="POST" action={flow?.ui.action}>
          <input type="hidden" {...form.register("csrf_token")} />
          <Box direction="row" justify="center" space="small">
            <IconButton name="provider" value="apple">
              <Icon icon="apple" />
            </IconButton>
            <IconButton name="provider" value="facebook">
              <Icon icon="facebook" />
            </IconButton>
            <IconButton name="provider" value="google">
              <Icon icon="google" />
            </IconButton>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export { InputEmail };
