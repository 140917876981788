import { init, track as trackEvent } from "@amplitude/analytics-browser";

import amplitudeConfig from "~/config/amplitude";
import { usePlanturaStore } from "~/stores/plantura";

import { Event } from "./types";

class AmplitudeClientBrowser {
  constructor(apiKey: string) {
    init(apiKey, { defaultTracking: false, serverZone: "EU" });
  }

  track(event: Event) {
    const { name, payload } = event.toJson();
    const client = usePlanturaStore.getState().getClient();

    trackEvent(name, {
      ...payload,
      source: client,
    });
  }
}

class AmplitudeClientMock {
  track(_event: Event) {
    console.error("Amplitude: Client only for browser environment");
  }
}

const createClient = () => {
  return globalThis.window
    ? new AmplitudeClientBrowser(amplitudeConfig.publicApiKey)
    : new AmplitudeClientMock();
};

const analytics = createClient();

export { analytics };
