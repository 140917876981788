import { Event, Screen } from "../types";

export class FlowRedirectEvent implements Event {
  constructor(
    private payload: {
      id: string;
      code: number;
      message: string;
      reason: string;
      redirect_browser_to?: string;
      screen: Screen;
    },
  ) {}

  toJson() {
    return {
      name: "flow: redirect",
      payload: this.payload,
    };
  }
}
