import { Event } from "../types";

export class ScreenShownSocialEvent implements Event {
  constructor(private payload: { provider: string }) {}

  toJson() {
    const provider = this.payload.provider;
    const name = `show ${provider} login screen`;
    return {
      name,
      payload: this.payload,
    };
  }
}
