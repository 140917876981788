import { RecoveryFlow, UpdateRecoveryFlowBody } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components/full-screen-loader";
import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import {
  analytics,
  ButtonClickEvent,
  ScreenShownEvent,
  trackFlow,
} from "~/libs/analytics";
import { pathWithQuery } from "~/libs/http";
import { handleFlowError, setFormValues, setUriFlow } from "~/libs/ory";
import {
  Box,
  Button,
  Error,
  FormField,
  Heading,
  InputField,
  Text,
} from "~/ui/components";

import { FlowMessage } from "./components/flow-message";

type FormData = {
  csrf_token: string;
  code: string;
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const ory = useOry();
  const form = useForm<FormData>();
  const [recoveryInProgress, setRecoveryInProgress] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const [identifier, setIdentifier] = useState<string>();
  const [step, setStep] = useState<"default" | "verify-code">("default");
  const [flow, setFlow] = useState<RecoveryFlow>();

  const { flow: flowId, return_to: returnTo } = router.query;

  const onSubmitForgotPassword = async (data: FormData) => {
    if (!flow) return;

    await setUriFlow(router, flow.id);

    const body = {
      csrf_token: data.csrf_token,
      email: identifier,
      method: "code",
    } as UpdateRecoveryFlowBody;

    try {
      setRecoveryInProgress(true);

      analytics.track(
        new ButtonClickEvent({
          button: "send code",
          screen: "pw-reset: start",
        }),
      );

      const { data } = await ory.updateRecoveryFlow({
        flow: flow.id,
        updateRecoveryFlowBody: body,
      });

      setFlow(data);
      trackFlow({ flow: data, screen: "pw-reset: start" });
      data.state === "sent_email" && setStep("verify-code");

      analytics.track(
        new ScreenShownEvent({
          screen: "pw-reset: code input",
        }),
      );
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "pw-reset: start" });
      await handleFlowError(router, "recovery")(error);
    } finally {
      setRecoveryInProgress(false);
    }
  };

  const onSubmitVerifyCode = async (data: FormData) => {
    if (!flow) return;

    await setUriFlow(router, flow.id);

    const body = {
      csrf_token: data.csrf_token,
      code: data.code,
      method: "code",
    } as UpdateRecoveryFlowBody;

    try {
      setRecoveryInProgress(true);

      analytics.track(
        new ButtonClickEvent({
          button: "verify code",
          screen: "pw-reset: code input",
        }),
      );

      const { data } = await ory.updateRecoveryFlow({
        flow: flow.id,
        updateRecoveryFlowBody: body,
      });

      setFlow(data);
      trackFlow({ flow: data, screen: "pw-reset: code input" });
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "pw-reset: code input" });

      switch (error.response?.data?.error?.id) {
        case "browser_location_change_required":
          router.push(
            pathWithQuery(error.response.data.redirect_browser_to, {
              source: "forgot-password",
            }),
          );
          return;
      }

      await handleFlowError(router, "recovery")(error);
    } finally {
      setRecoveryInProgress(false);
    }
  };

  useEffect(() => {
    const recoveryFlow = async () => {
      if (!router.isReady || flow) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getRecoveryFlow({ id: String(flowId) });
          setFlow(data);
          setFormValues(form, data);
          trackFlow({ flow: data, screen: "pw-reset: start" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;
          trackFlow({ flow: error.response?.data, screen: "pw-reset: start" });
          await handleFlowError(router, "recovery")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserRecoveryFlow();
        setFlow(data);
        setFormValues(form, data);
        trackFlow({ flow: data, screen: "pw-reset: start" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        trackFlow({ flow: error.response?.data, screen: "pw-reset: start" });
        await handleFlowError(router, "recovery")(error);
      }
    };

    recoveryFlow();
  }, [router, router.isReady, flow, flowId, returnTo, ory, form]);

  useEffect(() => {
    if (!router.isReady) return;

    const { identifier } = router.query;

    if (!identifier || typeof identifier !== "string") {
      router.push("/login");
      return;
    }

    setIdentifier(identifier);
  }, [router, router.isReady]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "pw-reset: start",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("forgotPassword.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          {step === "default" && (
            <Box space="large">
              <Heading element="h3">{t("forgotPassword.heading")}</Heading>
              <FlowMessage flow={flow}></FlowMessage>
              <Text>
                {t("forgotPassword.introductory[0]")}
                &nbsp; <b>{identifier}</b> &nbsp;
                {t("forgotPassword.introductory[1]")}
              </Text>
              <form onSubmit={handleSubmit(onSubmitForgotPassword)}>
                <input type="hidden" {...register("csrf_token")} />

                <Button disabled={recoveryInProgress} fullWidth>
                  Code senden
                </Button>
              </form>
            </Box>
          )}

          {step === "verify-code" && (
            <form onSubmit={handleSubmit(onSubmitVerifyCode)}>
              <input type="hidden" {...register("csrf_token")} />

              <Box space="large">
                <Heading element="h3">{t("resetPassword.heading")}</Heading>
                <Text>
                  {t("resetPassword.introductory")} <b>{identifier}</b>
                </Text>
                <FlowMessage flow={flow}></FlowMessage>
                <FormField>
                  <InputField
                    autoComplete="one-time-code"
                    placeholder={t<string>("resetPassword.code")}
                    {...register("code")}
                  />
                  {errors.code && <Error>{errors.code.message}</Error>}
                </FormField>
                <Button disabled={recoveryInProgress} fullWidth>
                  {t("common.continue")}
                </Button>
              </Box>
            </form>
          )}
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { ForgotPassword };
