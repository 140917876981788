import { Event, Screen } from "../types";

export class FlowEvent implements Event {
  constructor(
    private payload: {
      screen: Screen;
      flowId: string;
      flowUrl?: string;
      messageId: number;
      messageType: "error" | "success" | "info";
      messageText: string;
    },
  ) {}

  private extractFlowTypeFromUrl(flowUrl?: string) {
    if (!flowUrl) return;

    const match = /self-service\/(.*?)\/browser/.exec(flowUrl);
    if (match) return match[1];

    try {
      const url = new URL(flowUrl);
      return url.pathname.split("/").pop();
    } catch (error) {
      return "unable to extract type from invalid url";
    }
  }

  toJson() {
    return {
      name: `flow: ${this.payload.messageType} message`,
      payload: {
        screen: this.payload.screen,
        "flow id": this.payload.flowId,
        "flow url": this.payload.flowUrl,
        "flow type": this.extractFlowTypeFromUrl(this.payload.flowUrl),
        "message id": this.payload.messageId,
        "message type": this.payload.messageType,
        "message text": this.payload.messageText,
      },
    };
  }
}
