/* eslint-disable react-hooks/exhaustive-deps */
import { RegistrationFlow, UpdateRegistrationFlowBody } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import { analytics, ButtonClickEvent  } from "~/libs/analytics";
import { handleFlowError, setFormValues, setFormErrors } from "~/libs/ory";
import { isValidEmail } from "~/libs/utils";
import {
  Button,
  InputField,
  Box,
  Heading,
  Text,
  FormField,
  Error,
} from "~/ui/components";

type Props = {
  flow: RegistrationFlow;
};

type FormData = {
  csrf_token: string;
  provider: string;
  traits: {
    email: string;
  };
};

const OidcCollectMissingData = ({ flow }: Props) => {
  const { t } = useTranslation();
  const ory = useOry();
  const router = useRouter();
  const form = useForm<FormData>();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  useEffect(() => {
    setFormValues(form, flow);
  }, [flow]);

  const onSubmit = async (data: FormData) => {
    const body = {
      csrf_token: data.csrf_token,
      provider: data.provider,
      traits: {
        email: data.traits.email,
      },
      method: "oidc",
    } as UpdateRegistrationFlowBody;

    try {
      analytics.track(
        new ButtonClickEvent({
          button: "continue",
          screen: "registration: social missing email",
        }),
      );

      await ory.updateRegistrationFlow({
        flow: String(flow.id),
        updateRegistrationFlowBody: body,
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) return;
      await handleFlowError(router, "registration")(error);

      // form validation error
      if (error.response?.status === 400) {
        setFormErrors(form, error.response?.data);
      }
    }
  };

  return (
    <>
      <Head>
        <title>{t("socialRegister.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Box space="small">
              <Heading element="h3">{t("socialRegister.heading")}</Heading>
              <Text>{t("socialRegister.introductory")}</Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box space="small">
                <input type="hidden" {...register("csrf_token")} />
                <input type="hidden" {...register("provider")} />

                <FormField>
                  <InputField
                    type="email"
                    autoComplete="username"
                    placeholder={t<string>("common.email")}
                    {...form.register("traits.email", {
                      validate: {
                        matchPattern: (v) =>
                          isValidEmail(v) ||
                          t("common.invalidEmail").toString(),
                      },
                    })}
                  />
                  {errors?.traits?.email && (
                    <Error>{errors?.traits?.email?.message}</Error>
                  )}
                </FormField>
                <Button>{t("common.continue")}</Button>
              </Box>
            </form>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { OidcCollectMissingData };
