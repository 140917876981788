import { LoginFlow } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { FullScreenLoader } from "~/components";
import { AuthLayout } from "~/features/auth";
import { FlowMessage } from "~/features/auth/components";
import { useOry } from "~/hooks/use-ory";
import { analytics, ButtonClickEvent, ScreenShownEvent, trackFlow } from "~/libs/analytics";
import { urlForPath } from "~/libs/http";
import { handleFlowError, setFormValues } from "~/libs/ory";
import { Box, Button, Heading, Icon, Seperator, Text } from "~/ui/components";

type FormData = {
  csrf_token: string;
};

const LoginSplash = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const form = useForm<FormData>();
  const ory = useOry();

  const [flow, setFlow] = useState<LoginFlow>();

  const { register } = form;

  const {
    flow: flowId,
    refresh, // if set we want to refresh the session
    aal, // implies you want to perform two-factor authentication/verification.
  } = router.query;

  const trackContinueWithEmail = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "continue with email",
        screen: "home",
      }),
    );
  };

  const trackTermsOfService = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "terms of service",
        screen: "home",
      }),
    );
  };

  const trackPrivacyPolicy = () => {
    analytics.track(
      new ButtonClickEvent({
        button: "privacy policy",
        screen: "home",
      }),
    );
  };

  const onSocialLogin = async (provider: string) => {
    const csrfToken = form.getValues("csrf_token");

    if (!flow || !csrfToken) return;

    analytics.track(
      new ButtonClickEvent({
        button: `continue with ${provider}`,
        screen: "home",
      }),
    );

    try {
      await ory.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: {
          csrf_token: csrfToken,
          method: "oidc",
          provider,
          upstream_parameters: {
            ...(provider === "apple" && { prompt: "login" }),
            ...(provider === "google" && { prompt: "login" }),
            ...(provider === "facebook" && { auth_type: "reauthenticate" }),
          },
        },
      });
    } catch (error) {
      if (!axios.isAxiosError(error)) throw error;
      trackFlow({ flow: error.response?.data, screen: "home" });

      await handleFlowError(router, "login")(error);
    }
  };

  useEffect(() => {
    const loginFlow = async () => {
      if (!router.isReady) {
        return;
      }

      if (flowId) {
        try {
          const { data } = await ory.getLoginFlow({
            id: String(flowId),
          });
          setFlow(data);
          setFormValues(form, data);
          trackFlow({ flow: data, screen: "home" });
        } catch (error) {
          if (!axios.isAxiosError(error)) throw error;
          trackFlow({ flow: error.response?.data, screen: "home" });
          await handleFlowError(router, "login")(error);
        }
        return;
      }

      try {
        const { data } = await ory.createBrowserLoginFlow({
          refresh: Boolean(refresh),
          returnTo: urlForPath(`/api/redirect`),
        });

        setFlow(data);
        setFormValues(form, data);
        trackFlow({ flow: data, screen: "home" });
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
        trackFlow({ flow: error.response?.data, screen: "home" });
        await handleFlowError(router, "login")(error);
      }
    };

    loginFlow();
  }, [aal, flowId, form, ory, refresh, router, router.isReady]);

  const screenShown = useCallback(() => {
    analytics.track(
      new ScreenShownEvent({
        screen: "home",
      }),
    );
  }, []);

  useEffect(() => screenShown(), [screenShown]);

  if (!flow) {
    return <FullScreenLoader />;
  }

  return (
    <>
      <Head>
        <title>{t("loginSplash.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Box space="small">
              <Heading element="h3">{t("loginSplash.heading")}</Heading>
              <FlowMessage flow={flow}></FlowMessage>
              <Text variant="secondary">{t("loginSplash.introductory")}</Text>
            </Box>
            <form>
              <input type="hidden" {...register("csrf_token")} />
              <Box space="small" className="social-login__wrapper">
                <Button
                  type="button"
                  variant="light"
                  startIcon={<Icon icon="apple" />}
                  onClick={() => onSocialLogin("apple")}>
                  {t("common.loginWithApple")}
                </Button>
                <Button
                  type="button"
                  variant="light"
                  startIcon={<Icon icon="facebook" />}
                  onClick={() => onSocialLogin("facebook")}>
                  {t("common.loginWithFacebook")}
                </Button>
                <Button
                  type="button"
                  variant="light"
                  startIcon={<Icon icon="google" />}
                  onClick={() => onSocialLogin("google")}>
                  {t("common.loginWithGoogle")}
                </Button>
              </Box>
            </form>

            <Seperator>{t("common.seperator")}</Seperator>

            <Link
              onClick={trackContinueWithEmail}
              href={{
                pathname: "/login",
                query: { ...(refresh && { refresh }) },
              }}>
              <Button fullWidth>{t("loginSplash.continueWithEmail")}</Button>
            </Link>

            <Text size="small">
              {t("loginSplash.registerTerms[0]")}{" "}
              <Link
                onClick={trackTermsOfService}
                href="https://shop.plantura.garden/policies/terms-of-service"
                target="_blank">
                <Text element="span" variant="primary">
                  {t("loginSplash.registerTerms[1]")}{" "}
                </Text>
              </Link>
              {t("loginSplash.registerTerms[2]")}{" "}
              <Link
                onClick={trackPrivacyPolicy}
                href="https://shop.plantura.garden/policies/privacy-policy"
                target="_blank">
                <Text element="span" variant="primary">
                  {t("loginSplash.registerTerms[3]")}
                </Text>
              </Link>
            </Text>

            <Text className="create-account__wrapper">
              {t("loginSplash.newHere")}{" "}
              <Link href="/login">
                <Text element="span" variant="primary">
                  {t("loginSplash.createAccount")}
                </Text>
              </Link>
            </Text>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;

  .social-login__wrapper > ${Button.Root} {
    justify-content: flex-start;
  }

  .create-account__wrapper {
    font-weight: bold;
  }
`;

export { LoginSplash };
