import {
  LoginFlow,
  RecoveryFlow,
  RegistrationFlow,
  SettingsFlow,
  VerificationFlow,
} from "@ory/client";

import { uiTextFormattedMessage } from "~/libs/ory/helpers";
import { Alert } from "~/ui/components";

type Props = {
  flow?:
    | LoginFlow
    | RecoveryFlow
    | RegistrationFlow
    | SettingsFlow
    | VerificationFlow;
};

const FlowMessage = ({ flow }: Props) => {
  const flowMessages = flow?.ui?.messages || [];
  const message = flowMessages[flowMessages.length - 1];

  if (!message) return <></>;

  // Do not show dynamic flow message for forgot password: code sent
  if (message.id === 1060003) {
    return <></>;
  }

  return <Alert text={uiTextFormattedMessage(message)} />;
};

export { FlowMessage };
