import { Event, Screen } from "../types";

export class ScreenShownEvent implements Event {
  constructor(private payload: { screen: Screen }) {}

  toJson() {
    const screen = this.payload.screen;
    const name = `show ${screen.replace(":", " ")} screen`;
    return {
      name,
      payload: this.payload,
    };
  }
}
