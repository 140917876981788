import Head from "next/head";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { bugsnag } from "~/libs/bugsnag";
import { ErrorBody } from "~/libs/types";
import { Box, Text } from "~/ui/components";

import { AuthLayout } from "./auth-layout";

export type Props = {
  error: ErrorBody;
};

const Start = ({ error }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    const reportToBugsnag = () => {
      if (error) {
        console.log(error); // intentional

        bugsnag.notify("Invalid auth start flow", (event) => {
          event.addMetadata("app", "requestId", error.requestId);
          event.addMetadata("metadata", error);
        });
      }
    };

    reportToBugsnag();
  }, [error]);

  return (
    <>
      <Head>
        <title>{t("common.appName")}</title>
      </Head>
      {error && (
        <AuthLayout>
          <Container>
            <Box>
              <StartError errorDetail={t("startFlow.errorDetail")} />
            </Box>
          </Container>
        </AuthLayout>
      )}
    </>
  );
};

const StartError = ({ errorDetail }: { errorDetail: string }) => {
  return (
    <Box space="large">
      <Text>{errorDetail}</Text>
    </Box>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { Start };
