import { FlowError } from "@ory/client";
import axios from "axios";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AuthLayout } from "~/features/auth";
import { useOry } from "~/hooks/use-ory";
import { Box, Text } from "~/ui/components";

const Error = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const ory = useOry();

  const [flowError, setFLowError] = useState<FlowError>();

  const { id: flowErrorId } = router.query;

  useEffect(() => {
    const errorFlow = async () => {
      if (!router.isReady || !flowErrorId) {
        return;
      }

      try {
        const { data } = await ory.getFlowError({ id: String(flowErrorId) });
        setFLowError(data);
      } catch (error) {
        if (!axios.isAxiosError(error)) throw error;
      }
    };

    errorFlow();
  }, [flowErrorId, ory, router, router.isReady]);

  const message = flowError && (flowError.error as { message: string }).message;

  return (
    <>
      <Head>
        <title>{t("error.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Box space="small">
              <Text variant="default" size="default">
                {message}
              </Text>
            </Box>
            <Box space="small">
              <Link href={`/`}>{t("common.back")}</Link>
            </Box>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { Error };
