import Head from "next/head";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AuthLayout } from "~/features/auth";
import { Button, InputField, Box, Heading, Text } from "~/ui/components";

const SocialRegisterWithEmail = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t("socialRegister.title")}</title>
      </Head>
      <AuthLayout>
        <Container>
          <Box space="large">
            <Box space="small">
              <Heading element="h3">{t("socialRegister.heading")}</Heading>
              <Text>{t("socialRegister.introductory")}</Text>
            </Box>
            <Box space="small">
              <InputField
                type="email"
                placeholder={t<string>("common.email")}
              />
              <Button>{t("common.continue")}</Button>
            </Box>
          </Box>
        </Container>
      </AuthLayout>
    </>
  );
};

const Container = styled.div`
  text-align: center;
`;

export { SocialRegisterWithEmail };
