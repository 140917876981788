import { UseFormReturn } from "react-hook-form";

export enum Step {
  InputEmail = "input email",
  InputPassword = "input password",
  SelectProvider = "social account",
}

export type StepTransition =
  | {
    from: Step.InputEmail;
    to: Step.InputPassword;
    payload: { email: string };
  }
  | {
    from: Step.InputEmail;
    to: Step.SelectProvider;
    payload: { email: string; loginProviders: string[] };
  };

export type FormData = {
  csrf_token: string;
  method: string;
  identifier: string;
  password: string;
};

export type FormReturn = UseFormReturn<FormData>;
